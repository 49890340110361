.auth-card {
  padding: 95px 50px 50px 50px;
  border-radius: 20px;
  width: 550px;
}

.auth-card-title {
  text-transform: uppercase;
  font-size: 48px;
  line-height: 65px;
  text-align: center;
  letter-spacing: -0.015em;
  color: #263238;
  font-weight: normal;
}

//mobile
@media(max-width: 576px){
  .auth-card{
    margin: 0 auto; //centraliza o que tem por fora
    width: 90vw;
    padding: 10px;
    display: flex;//centraliza o que tem por dentro
    align-items: center;
    flex-direction: column;
  }
}
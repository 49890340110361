.main-nav-admin {
  width: 100% !important;
  height: 70px;
  color: #fff;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  padding: 0 40px;
  margin: 0;
}
.nav-logo-text {
  width: 30%;
  color: #fff;
}

.menu-mobile-btn{
  display: none;
}

.nav-link {
  font-size: 18px;
  font-style: normal;
  color: rgba(255, 255, 255, 0.5);

  &.active {
    font-weight: bold;
    color: #fff;
  }

  &:hover {
    color: #fff;
  }
}


//tablet breakpoint
@media(max-width: 768px){
  .menu-container{
    justify-content: flex-end;
  }
}

//mobile breakpoints
@media(max-width: 576px){

  .main-nav-admin{
    width: 100% !important;
    justify-content: space-between;
  }
  .menu-container, .user-info-dnone {
    display: none;
  }
  .nav-logo-text{
    width: 50%;
    margin-left: 15px;
  }
  .menu-mobile-btn{
    display: block;
    border: none;
    background: transparent;
    width: 30px;
    outline: none;//retira a borda
  }
  .menu-mobile-container{
    width: 100%;
    top: 70px;
    left: 0;
    display: flex;
    flex-direction: column;
    padding: 10px;
    background: #407bff;
    position: absolute;
    z-index: 10;

    ul{
      height: 150px;
      display: flex;
      align-items: flex-start;
      flex: 1;
      flex-direction: column;
      top: 40px;

      li{
        width: 96vw;
        margin: 5px 0px;

      }
    }
  }

}


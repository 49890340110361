.auth-container {
  display: flex;
  padding: 40px 40px 100px 0px;
  justify-content: center;
  background: url(~core/assets/images/auth-background.svg) no-repeat bottom;
  background-size: 100%;

}

.auth-info-title {
  margin-top: 30px;
  font-size: 55px;
  line-height: 75px;
  letter-spacing: -0.015em;

  color: #263238;
}

.auth-info-subtitle {
  margin-top: 25px;
  margin-bottom: 15px;
  font-size: 24px;
  line-height: 33px;
  letter-spacing: -0.015em;

  color: #9e9e9e;
}

//tablet
@media(max-width: 768px){
  .auth-info{
    display: none;
  }
}

/*mobile*/
@media(max-width: 576px){
  .auth-container{
    margin: 0px;
    padding: 40px 0px 0px 0px;
  }
}
.product-main-img {
  border: 1px solid #EEE;
  overflow: hidden;
}

.product-main-img img {
  width: 100%;
  transition: 0.5s ease;
}

.product-main-img img:hover{
  transform: scale(1.5);
  -webkit-transform: scale(1.5);
  -moz-transform: scale(1.5);
  -ms-transform: scale(1.5);
  -o-transform: scale(1.5);
  transform: scale(1.5);
  -webkit-transform-origin: 0px 0px;
  -moz-transform-origin: 0px 0px;
  -ms-transform-origin: 0px 0px;
  -o-transform-origin: 0px 0px;
  transform-origin: 0px 0px;
}

.description-content > ul{
  margin-left: 15px;
  list-style: outside;
}

.img-thumb {
  border: 1px solid #EEE;
  margin-right: 2px;
  margin-top: 2px;
  cursor: pointer;
}

.selected-thumb {
  border: 2px solid #CCC;
}

.active-thumb, .img-thumb:hover {
  border: 2px solid #666;
}
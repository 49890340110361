.main-nav {
  min-height: 60px;
  background-color: #112a49!important;
  font-family: "Roboto Condensed", sans-serif;
  text-transform: uppercase;
}

.nav-logo-text, .nav-logo-text:hover {
  color: #FFF;
}

.main-menu {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  margin-top: 10px;
}

.main-menu li {
  margin-right: 22px;
}

.main-menu a {
  font-style: normal;
  color: #FFF;
}

.main-menu a:hover {
  color: #CCC;
  text-decoration: underline;
}

.main-menu a.active {
  color: #CCC;
  text-decoration: underline;
}

@media (min-width: 576px) {
  .main-menu {
    margin-top: 0;
  }  
}

@media (max-width: 768px) {
  .main-menu li {
    padding: 4px;
  }
}
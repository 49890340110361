.product-filters-container {
  width: 70%;
  padding: 10px 20px;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
}

.input-search {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e1e1e1;
  flex: 1;

  input {
    background-color: #FFF;
    border: none;

    &::placeholder {
      color: #9e9e9e;
    }

    &:focus {
      box-shadow: none;
      background-color: #FFF;
    }
  }
}

.filter-select-container {
  flex: 1;
  // margin-left: 50px;
  // margin-right: 50px;

  .product-categories-select__control {
    height: 30px;
    border: none;
    border-radius: 0;
    border-bottom: 1px solid #e1e1e1;
  }

  .product-categories-select__indicator-separator {
    display: none;
  }

  .product-categories-select__placeholder {
    color: #9e9e9e !important;
  }
}
//tablet
// @media (max-width: 768px) {
//   .product-filters-container {
//     width: 100%;
//   }
// }

//mobile
@media (max-width: 768px) {
  .product-filters-container {
    width: 100%;
    height: 200px;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px;
  }

  .filter-select-container {
    width: 100%;
    margin: 10px 0px !important;
  }
}

@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300;400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@100;400&family=Roboto+Condensed:wght@300;400;700&display=swap');

h1, h2, h3, h4, h5, h6 {
  margin-bottom: 0;
  //font-weight: 700;
}

body {
  background-color: #FFF;
  font-family: Arial, Helvetica, sans-serif;
}

a {
  text-decoration: none;
}

.main-content {
  min-height: 370px;
}

ul {
  list-style-type: none;
  margin-bottom: 0;
  padding-left: 0;
}

.list-marked {
  margin-left: 15px;
  list-style: square;
}

a {
  &:hover {
    text-decoration: none;
    color: unset;
  }
}

.border-radius-10 {
  border-radius: 10px;
}
.border-radius-20 {
  border-radius: 20px;
}

.card-base {
  background: #ffffff;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
}

.margin-bottom-30{
  margin-bottom: 30px;
}

.input-base {
  border: 1px solid #e1e1e1;
  box-sizing: border-box;
  border-radius: 10px;
  font-size: 18px;
  line-height: 25px;
  letter-spacing: -0.015em;
  color: #263238;
  height: 50px;
  background-color: #FFF;

  &::placeholder {
    color: #9e9e9e;
  }
}

.btn-outline-secondary{
  color: #9E9E9E;
}

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}

.btn-action-adm, .btn-action-adm:hover {
  color: #FFF;
}

.btn-outline-secondary {
  border-color: #E1E1E1;
}

.btn-outline-secondary:hover {
  background-color: #E1E1E1;
}

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545;
}

.btn-outline-danger:hover {
  background: #dc3545;
  color: #FFF;
}

.mr-3 {
  margin-right: 10px;
}
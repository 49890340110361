.main-row{
  display: flex;
  flex-direction: row;
  .col-user{
    width: 80%;
  }
  .col-buttons{
    width: 50%;
    .row-buttons{
      display: flex;
      flex-direction: row;
      .col-btn{
        width: 50%;
        margin-left: 10px;
        
      }
    }
  
  }
}
.card-item {

  h2 {
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 25px;
    letter-spacing: -0.015em;
  }

  span {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: -0.015em;

    color: #9e9e9e;
  }
}
//tabelet
@media (max-width: 768px) {
  .main-row{
    display: flex;
    flex-direction: row;
    .col-user{
      width: 50%;
    }
    .col-buttons{
      width: 70%;
      .row-buttons{
        display: flex;
        flex-direction: row;
        .col-btn{
          
          width: 45%;
          margin-left: 8px;

          a,button{
            font-size: 15px;
          }
          
        } 
      }
    }
  }
}
.slider-area {
  width: 100%;
  text-align: center;
  /* margin-top: 5px; */
}

@media (max-width: 576px) {
  .slider-area img {
    display: none;
  }
}

@media (max-width: 992px) {
  .slider-area img {
    width: 100%;
  }
}
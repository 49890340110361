header {
  min-height: 150px;
}

.logo {
  display: flex;
  align-items: center;
  height: 150px;
}

.search-input  {
  margin-top: 56px;
  background: none repeat scroll 0 0 #FFF;
} 

@media (max-width: 576px) {
  .logo {
    justify-content: center;
  }

  .search-input  {
    margin-top: 10px;
    margin-bottom: 15px;
  }
}
.filters-container {
  width: 70%;
  padding: 10px 20px;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;

  .select-container {
    margin-right: 22px;
  }
}

.filter-select-container {
  flex: 1;
  margin-left: 50px;
  margin-right: 50px;

  .default-select__control {
    width: 130%;
    margin-right: 100px;
    height: 30px;
    border: none;
    border-radius: 0;
    border-bottom: 1px solid #e1e1e1;
  }

  .default-select__indicator-separator {
    display: none;
  }

  .default-select__placeholder {
    color: #9e9e9e !important;
  }
}

//tablet breakpoint
// @media (max-width: 768px) {
//   .filters-container {
//     width: 100%;
//   }
// }
//mobile breakpoints
@media (max-width: 768px) {
  .filters-container {
    width: 100%;
    display: flex;
    flex-direction: column;
   
  }
  .select-container {
    width: 100%;
    .filter-select-container {
      width: 78%;
      margin: 10px 0px !important;
      
    }
  }
}

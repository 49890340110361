#contactForm input, #contactForm textarea {
  margin-top: 10px;
  background-color: #EEE;
}

#contactForm input[type="submit"] {
  background: none repeat scroll 0 0 #112a49;
  border: medium none;
  color: #fff;
  display: inline-block;
  font-size: 16px;
  padding: 7px 20px;
  text-transform: uppercase;border: 1px solid #112a49;
}

#contactForm input[type="submit"]:hover {
  background: #222;
  border-color: #666
}

.contact-left li a{
  color: var(--bs-body-color);
}
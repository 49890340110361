.brands-area {
  border-top:1px solid #e5e5e5;
  border-bottom:1px solid #e5e5e5;
  color: #fff;
  padding: 20px 0 20px;
  text-align: center;
  margin-top: 20px;
}

.brand-list img {
  height: 72px;
}

.brand-list img:hover {
  opacity: 0.5;
}
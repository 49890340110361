.login-form {
  margin-top: 80px;
}

.login-link-recover {
  font-size: 18px;
  line-height: 25px;
  letter-spacing: -0.015em;
  color: #407bff;
  margin-top: 15px;
  display: block;
}

.login-link-register {
  font-weight: bold;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  letter-spacing: -0.015em;
  text-decoration-line: underline;
  color: #407bff;
  margin-left: 10px;
}

.login-submit {
  margin-top: 100px;
  margin-bottom: 35px;
}

.not-registered {
  font-weight: bold;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  letter-spacing: -0.015em;
  color: #9e9e9e;
}

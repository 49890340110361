.upload-button-container {
  label {
    background-color: #9e9e9e;
    border-radius: 10px;
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    letter-spacing: -0.015em;
    color: #ffffff;
    padding: 10px 30px;
    cursor: pointer;
  }
}

.upload-text-helper {
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: -0.015em;
}

.upload-placeholder{
  .upload-progress-container{
    position:absolute;
    bottom: 10px;
    left: 25px;
    height: 10px;
    background: #E1E1E1;
    width: 175px;
    border-radius: 10px;
  }

  .upload-progress{
    background: #407BFF;
    border-radius: 10px;
    height: 10px;
    width: 15%;
  }

  .uploaded-image{
    width: 200px;
    height: 200px;
  }
}
//tabelet
@media(max-width: 768px){
  .upload-button-container label{
    word-wrap: none;
    width: 200px;
  }
}

//mobile
@media(max-width: 576px){
  .upload-button-container label{
    width: 250px;//tamanho dos outros inputs

  }
  small{
    display: none;
  }
  .upload-placeholder{
    width: 100vw !important;
    margin-left: 40px;

    .uploaded-image{
      align-self: center;
    }

    .upload-progress{
       margin-left: -20px;
    }
    .upload-progress-container{
      margin-left: 20px;
    }
  }
}
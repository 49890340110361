.admin-nav-container {
  width: 300px;
  background-color: #fff;
  // height: calc(100vh - 70px);
  height: calc(100vh - 5px);
  //height: 100vh;
}

.admin-nav-item {
  height: 80px;
  display: block;
  border-bottom: 1px solid #e1e1e1;
  display: flex;
  align-items: center;
  padding-left: 40px;

  font-weight: bold;
  font-size: 18px;
  line-height: 25px;
  letter-spacing: -0.015em;

  color: #9e9e9e;

  &.active {
    background: rgba(64, 123, 255, 0.3);
    color: #407bff;
  }

  &:hover {
    color: #407bff;
  }
}

//tabelet
@media (max-width: 768px) {
  .admin-nav-container {
    width: 100%;
    height: 120px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .admin-nav-container ul {
    width: 100%;
    display: flex !important;
    flex-direction: row;
    justify-content: space-around; //espaço entre os itens e antes e depois
    align-items: center;
  }

  .admin-nav-item {
    width: 200px;
    height: 60px;
    border-radius: 40px;
    display: flex;
    justify-content: center;
    padding-left: 0px;
    background: #f2f2f2;
    margin-top: 32px;
  }
}

//mobile
@media (max-width: 576px) {
  .admin-nav-container {
    display: flex;
    

    ul{
      justify-content: space-around;
      align-items: center;
    }
  }
  .admin-nav-item {
    font-size: 16px;
    width: 130px;
    height: 50px;
    text-align: center;
    
  }
}

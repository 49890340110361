.pagination-container{
  display: flex;
  justify-content: center;
  margin-top: 35px;
  margin-bottom: 35px;
  align-items: center;
}

.pagination-item{
  height: 40px;
  width: 40px;
  background-color: #E1E1E1;
  border-radius: 50%;
  font-weight: bold;
  font-size: 18px;
  line-height: 25px;
  letter-spacing: -0.015em;
  color: #FFF;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-right: 10px;

  &.active{
    background: #112a49;
  }
}

.pagination-previous{
  transform: rotate(-180deg);
  margin-right: 25px;
  cursor: pointer;
}

.pagination-next{
  margin-left: 15px;
  cursor: pointer;
}

.page-active{
  cursor: pointer;
  color: #112a49;
}

.page-inactive{
  color: #E1E1E1;
  pointer-events: none;
}
.product-form-container {
  display: flex;
  flex-direction: row;
}

.categories-select__control {
  height: 50px;
  border-radius: 10px !important;
  border: 1px solid #e1e1e1;
}

.categories-select__indicator-separator {
  display: none;
}

.categories-select__placeholder {
  color: #9e9e9e !important;
}

.btn-duplicate {
  float: right;
}

//mobile
@media (max-width: 576px) {
  .product-form-container {
    width: 100%;
    display: flex;
    flex-direction: column;

    input {
      width: 250px;
    }

    .categories-select__control {
      width: 250px;
      min-width: 50px;
      height: 100%;
    }

    .input-base {
      width: 250px;
    }
  }
}

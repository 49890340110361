.footer-top-area {
  background: none repeat scroll 0 0 #385973;
  color: #FFF;
  padding: 35px 0 30px;
} 
  
.footer-bottom-area {
  background: #000;
  color: #fff
}

.footer-about-us span {
    color: #5a88ca;
}

.footer-wid-title {
  font-family: raleway;
  font-size: 30px;
  font-weight: 100;color: #fff
}

.footer-about-us h2 {
  font-weight: 200;
}
.footer-about-us p {
  margin-top: 10px;
  margin-bottom: 0;
}
.footer-menu {
  margin-bottom: 15px;
}
.footer-menu ul {
    list-style: outside none none;
    margin: 0;
    padding: 0;
}
.footer-menu ul li {
    border-bottom: 1px dashed #b5b4b4;
    padding: 5px 0;
}
.footer-menu a {
    display: block;
    padding: 5px 0;color: #FFF;
} 

.footer-menu a:hover, .footer-menu a:focus {
  color: #112a49;
  text-decoration: underline;
}

.footer-social a {
  background: none repeat scroll 0 0 #112a49;
  color: #fff;
  display: inline-block;
  font-size: 20px;
  height: 40px;
  margin-bottom: 10px;
  margin-right: 10px;
  padding-top: 5px;
  text-align: center;
  width: 40px;border: 1px solid #112a49;
}

.footer-social a:hover {
  background-color: #222;
  border-color: #666
}

.newsletter-form input[type="email"] {
  background: none repeat scroll 0 0 #FFF;
  border: medium none;
  padding: 5px;
  width: 100%;
}

.newsletter-form input[type="submit"] {
  background: none repeat scroll 0 0 #112a49;
  border: medium none;
  color: #fff;
  display: inline-block;
  font-size: 16px;
  padding: 7px 20px;
  text-transform: uppercase;border: 1px solid #112a49;
  margin-top: 10px;
}

.newsletter-form input[type="submit"]:hover {
  background: #222;
  border-color: #666
}

.footer-bottom-area {
  background: none repeat scroll 0 0 #112a49;
  color: #fff;
  min-height: 60px;
}

.copyright > p {
  margin-top: 20px;
  text-align: center;
  font-size: 0.8em;
}

.footer-newsletter p, .footer-about-us h5 {
  font-size: 14px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.footer-about-us li a {
  color: #FFF;
}

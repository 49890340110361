.section-title {
  font-family: "Raleway", sans-serif;
  font-size: 45px;
  font-weight: 100;
  margin-bottom: 30px;
}

.card-top-container {
  text-align: center;
  padding: 16px 0;
  border: 1px solid #E1E1E1;  
}

.card-top-container img {
  height: 158px;
}

.card-bottom-container {
  padding: 15px 20px 30px 20px;
}

.card-bottom-container h6 {
  font-size: 18px;
  font-weight: normal;
  color: #263238;
  text-align: center;  
}

.card-top-container:hover {
  opacity: 0.5;
}

a:hover h6{
  text-decoration: underline;  
  color: #5a88ca;
}


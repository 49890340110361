.admin-base-form{
  padding: 70px 45px 50px 45px;
  border-radius: 20px;
  width: 1055px;
}

.base-form-title{
  font-size: 24px;
  line-height: 33px;
  letter-spacing: -0.015em;
  color: #263238;
  text-transform: uppercase;
  margin-bottom: 45px;
}

.base-form-actions{
  margin-top: 70px;
  text-align: right;
}

//tabelet
@media(max-width: 768px){
  .admin-base-form{
    width: 100%;
  }
}

//mobile
@media (max-width: 576px){
  .admin-base-form{
    width: 100%;
    flex-direction: column;
    margin-left: -15px;
  }
  
}
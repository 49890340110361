.main-row {
  display: flex;
  flex-direction: row;

  .col-name {
    width: 80%;
  }

  .col-category {
    width: 50%;
  }
}
.card-item {
  h2 {
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 25px;
    letter-spacing: -0.015em;
    vertical-align: middle;
    margin-top: 8px;
  }
}

.row-category-buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  .category-button {
    width: 180px;
    margin-left: 27px;
  }
}

//tabelet
@media (max-width: 768px) {
  .main-row {
    .col-name {
      width: 50%;
    }
    .col-category {
      width: 30%;
    }
  }

  .row-category-buttons{
    .category-button {
      width: 100px;
      
      margin-left: 10px;

      .a{
        font-size: 15px;
      }
    }
  }
}

//mobile
@media (max-width: 576px) {
  .main-row {
    .col-name {
      width: 40%;
      h2{
        font-size: 15px;
      }
    }


  }
}
